@import "../../res/consts/colors.scss";

.containerObjectiveDetailsPage {
  /*@media (max-width: 765px) {
    flex-direction: column;
    .questionsContainerObjectiveDetailsPage {
      width: 100%;
      padding: 12px !important;
      .questionsObjectiveDetailsPage {
        flex-direction: column;

        .col-5,
        .col-7 {
          width: 100% !important;
        }
        .col-5 {
          margin-bottom: 24px;
        }
      }
    }
  }*/
  p{
    
  }
  .diagramObjectiveDetailsPage {
    background: $diagram_blue;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;

    //@media (min-width: 765px) {
      min-height: calc(100vh - 148px);
    //}
    /*@media (max-width: 765px) {
      width: 100%;
      margin-bottom: 24px;
    }*/
    .arrowRightCardCarousel {
      right: -20px !important;
      /*@media (max-width: 992px) {
        right: 0 !important;
      }*/
    }

    .arrowLeftCardCarousel {
      left: -20px !important;
      //@media (max-width: 992px) {
      //  left: 0 !important;
      //}
    }
  }

  .columnTopObjectiveDetailsPage {
    display: flex;
    align-items: center;
    .columnTitleObjectiveDetailsPage {
      color: black;
      font-size: 18px;
      font-weight: bold;
      margin-right: 20px;
    }
    .columnCountObjectiveDetailsPage {
      font-size: 14px;
      line-height: 14px;
      color: $dark_grey;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: $light_grey;
    }
  }
  p {
    font-size: 14px;
    line-height: 19px;
    color: $black;
    word-break: break-word !important;
  }
  hr {
    color: $middle_grey;
  }
  .answerContainerObjectiveDetailsPage {
    border-radius: 5px;
    &.AnswerColor0 {
      background-color: $light_green;
    }
    &.AnswerColor2 {
      background-color: $light_red;
    }
    &.AnswerColor1 {
      background-color: $light_pink;
    }
    .answerDetails {
      font-size: 10px;
      line-height: 14px;
      color: $dark_grey;
      div {
        text-transform: uppercase;
      }
    }
  }
}
