.containerCardCarouselOpport {
  margin: 0 30px;
  position: relative;
  width: calc(100% - 60px);
  .arrowsCardCarouselOpport {
    .arrowLeftCardCarouselOpport {
      position: absolute;
      top: calc(50% - 23px);
      left: -20px;
    }
    .arrowRightCardCarouselOpport {
      position: absolute;
      top: calc(50% - 23px);
      right: -20px;
    }
    img {
      cursor: pointer;
      z-index: 222;
    }
  }
}
.overflowCardCarouselOpport {
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  &:focus-visible {
    border: unset;
    outline: unset;
  }
  &::-webkit-scrollbar-thumb {
    width: 5px;
    height: 5px;
    background: #6db6fe;
    border-radius: 3px;
  }
  &::-webkit-scrollbar {
    display: none;
  }
  &::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 5px;
    background: #0f56e0;
    border-radius: 3px;
  }
  .carouselCardCarouselOpport {
    display: flex;
    position: relative;
    z-index: 1;
    transition: 1s;
    height: 100%;
    margin-right: auto;
  }
}
