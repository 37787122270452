@import "../../../res/consts/colors.scss";

.UnAnsweredContainerScroolBar {
  overflow-y: auto;
  padding:5px 22px 5px 5px;
  margin: -5px 0 0 -5px;
  div:nth-child(1) {
    margin-top: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #6db6fe;
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    background: #f6f8fc;
  }

  &::-webkit-scrollbar {
    width: 17px;
  }
}
.textAreaWrapp {
  width: 100%;
}

.UnAnsweredContainer {
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #eaeef1;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
  display: flex;
  margin-top: 10px;
  padding: 5px 4px 5px 4px;

  .unAnsweredContainerWithoutLeftLine  {
    display: flex;
    width: 100%;
    flex-direction: column;

    &.unAnsweredContainerWithoutLeftLineAcive {
      flex-direction: column;

      .textAreaWrapp {
        width: 100%;
        height: 60px;
        margin: 10px 10px 10px 0;

        textarea {
          max-width: unset;
          caret-color: $dark_blue;
        }
      }

      .textAreaWrapp {
        width: 100%;
      }
    }
  }

  .blueCircleSpan {
    display: flex;
    position: relative;
    width: 100%;

    .blueCircle {
      width: 5px;
      height: 5px;
      border-radius: 50px;
      background: #0f56e0;
      position: absolute;
      right: 0;
    }
  }

  textarea {
    flex-grow: 1;
    width: calc(100% - 5px);
    border: unset;
    background: unset;
    word-wrap: auto;
    word-break: normal;
    resize: none;
    display: block;
    border: 1px solid #6db6fe;
    height: 30px;
    caret-color: transparent;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1c1c1c;

    &:focus-visible {
      border: unset;
      outline: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      background-color: rgb(189, 189, 189);
      border-radius: 2px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 5px;
      background-color: gray;
      margin: 5px 10px 5px 0px;
    }
    &::placeholder {
      color: rgba(234, 238, 241, 1);
    }
  }

  .messageIconWrap {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 15px;
    margin-top: -10px;
    margin-right: 15px;
    img {
      cursor: pointer;
    }
  }

  .kritikalPolosUnAnswerd {
    border-radius: 1px;
    border:1px solid;
    margin-right: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1c1c1c;
    width: 2px;
  }
}

.imageWrapper {
  button{
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.notNAswerNotif {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #babcc9;
}

.unAnsweredQuestionQuestionText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #1c1c1c;
  padding: 5px 15px 5px 0;
  word-break: break-word;
}

.mockScrool {
  position: absolute;
  background: #f6f8fc;
  right: 0;
  height: 363px;
  z-index: -1;
  border-radius: 5px;
}

@media only screen and (max-width: 749px) {
  .unAnsweredQuestionQuestionText,
  .cockpitTitle,
  .cockpitUnswerTitile,
  .asweredCount,
  {
    font-size: 25px;
    line-height: 25px;
  }

  .textAreaWrapp > textarea, {
    font-size: 25px;
    line-height: 23px;
  }

  .cockpitUnswerTitileBlock .answeredCountWrapp {
    top: -6px;
    padding: 18px;
  }

  .unAnsweredQuestionQuestionText.microsoftEdgeBrowser,
  .cockpitTitle.microsoftEdgeBrowser,
  .cockpitUnswerTitile.microsoftEdgeBrowser,
  {
    font-size: 29px;
    line-height: 25px;
  }

  .asweredCount.microsoftEdgeBrowser,
  .textAreaWrapp.microsoftEdgeBrowser > textarea {
    font-size: 29px;
    line-height: 17px;
  }

  .textAreaWrapp.microsoftEdgeBrowser {
    margin: 15px 0 10px;
  }

  .UnAnsweredContainer .unAnsweredContainerWithoutLeftLine.unAnsweredContainerWithoutLeftLineAcive .textAreaWrapp.microsoftEdgeBrowser textarea {
    line-height: 35px;
  }

  .answeredCountWrapp.microsoftEdgeBrowser {
    top: -6px;
  }
}

@media only screen and (max-width: 601px) {
  .unAnsweredQuestionQuestionText,
  .cockpitTitle,
  .cockpitUnswerTitile,
  {
    font-size: 27px;
  }

  .textAreaWrapp > textarea,
  .asweredCount {
    font-size: 27px;
    line-height: 20px;
  }

  .UnAnsweredContainer .unAnsweredContainerWithoutLeftLine.unAnsweredContainerWithoutLeftLineAcive .textAreaWrapp textarea {
    line-height: 27px;
  }
}

@media only screen and (max-width: 501px) {
  .unAnsweredQuestionQuestionText,
  .textAreaWrapp > textarea,
  .cockpitTitle,
  .cockpitUnswerTitile,
  .asweredCount,
  {
    font-size: 29px;
  }

  .textAreaWrapp > textarea,
  .asweredCount {
    font-size: 29px;
    line-height: 17px;
  }

  .UnAnsweredContainer .unAnsweredContainerWithoutLeftLine.unAnsweredContainerWithoutLeftLineAcive .textAreaWrapp textarea {
    line-height: 29px;
  }
}
