@import "../../res/consts/colors.scss";

nav{
    ol{
        margin-bottom: unset !important;
        li{
            font-size: 22px;
            font-weight: 600;
            line-height: 30px;
            color: white;

            &.active{
                color:$light_blue !important;
                font-size: 22px;
                font-weight: normal;
                cursor: pointer;
            }
        }
    }
}