@import "../../../res/consts/colors.scss";

.rowInfo {
  display: flex;

  .infoAboutCase {
    font-size: 16px;
    font-weight: 700;
    line-height: 14px;
    color: $gray;
    margin: 17px 40px 27px 0;
  }

  .infoAboutCompany {
    font-size: 18px !important;
    font-weight: 600;
    line-height: 16px !important;
    color: $black;
  }

  .separator {
    margin: 0 20px;
    font-size: 20px !important;
    position: relative;
    top: -2px;
  }

  @media only screen and (max-width: 749px) {
    .infoAboutCase{
      font-size: 25px !important;
    }

    .infoAboutCompany {
      margin: 10px 0 15px;
      font-size: 29px !important;
    }

    .separator {
      font-size: 33px !important;
    }

    .infoAboutCase.microsoftEdgeBrowser{
      margin: 5px 40px 25px 0;
      font-size: 29px !important;
    }

    .infoAboutCompany.microsoftEdgeBrowser{
      margin: 10px 0 28px;
      font-size: 29px !important;
    }

    .separator.microsoftEdgeBrowser{
      font-size: 33px !important;
    }
  }

  @media only screen and (max-width: 601px) {
    .infoAboutCase{
      font-size: 27px !important;
    }
  }

  @media only screen and (max-width: 501px) {
    .infoAboutCase {
      font-size: 29px !important;
    }
  }
}
