.textAreaWrappAnswer {
  width: 100%;
  height: 60px;
  margin: 10px 10px 10px 0;
  textarea {
    flex-grow: 1;
    width: 100%;
    border: unset;
    background: unset;
    word-wrap: auto;
    word-break: normal;
    resize: none;
    display: block;
    margin-right: 5px;
    border: 1px solid #6db6fe;
    height: 100%;
    caret-color:#0F56E0;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #1c1c1c;

    &:focus-visible {
      border: unset;
      outline: unset;
    }
    &::-webkit-scrollbar-thumb {
      width: 5px;
      height: 5px;
      background-color: rgb(189, 189, 189);
      border-radius: 2px;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    &::-webkit-scrollbar {
      display: block;
      width: 5px;
      height: 5px;
      background-color: gray;
      margin: 5px 10px 5px 0px;
    }
  }
  .messageIconWrap {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    gap: 15px;
    margin-top: -10px;
    margin-right: 15px;
    img {
      cursor: pointer;
    }
  }
}
