@import "../../res/consts/colors.scss";

.pageContainerMailLink{
    width:100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    
    .containerMailLink{
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .headerMailLink{
            background-color: $dark_blue;
            width: 100%;
            height: 104px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                width:204px;
                object-fit: cover;
            }
        }

    }
    .contentMailLink{
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: 50px;
        max-width: 460px;
        .titleMailLink{
            color:$black;
            font-weight: bold;
            font-size: 24px;
            line-height: 33px;
        }
        .subtitleMailLink{
            color:$black;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            margin-top: 30px;
        }
        a{
            color:$dark_blue;
            font-weight: normal;
            font-size: 18px;
            line-height: 25px;
            margin-top: 30px;
            text-decoration: unset;
        }
    
        .regardsMailLink{
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
            div{
                color:$black;
                font-size: 18px;
                line-height: 25px;
            }
            .lightRegardsMailLink{

            }
            .boldRegardsMailLink{
                font-weight: bold;
            }
        }
    }
}