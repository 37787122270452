@import "../../../res/consts/colors.scss";

.containerSimpleInput{
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
    position: relative;
    label{
        color:$black;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 5px;
        .starSimpleInput{
            font-size: 16px;
            line-height: 22px;
            color:$red;
            font-weight: bold;
            margin-left: 5px;
        }
    }
    input{
        border: 1px solid #EAEEF1;
        border-radius: 5px;
        background-color: white !important;
        padding: 10px;
        &:focus-visible{
            border:unset !important;
            background: white !important;
        }
    }
    .errorSimpleInput, .serverError{
        color:$red;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        top: 75px;
        position: absolute;
    }
}