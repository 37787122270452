.needToRefreshModal{
    position: absolute;
    background: #031120;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 14px;
    top: -40px;
    z-index: 10;
    width: 100%;

    span{
        color: white;
        font-size: 17px;
        line-height: 20px;
        margin-left: 14px;
    }
}
.needToRefreshOverlay{
    z-index: 9;
    height: 100%;
    position: fixed;
    top:0;
}