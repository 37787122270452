@import "../../../res/consts/colors.scss";

.containerDropdownInput {
  .dropdownDropdownInput {
    border: 1px solid #eaeef1;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    #dropdown-basic {
      width: 100%;
      text-align: left;
      display: flex;
      border: unset;
      padding: unset;
      border-radius: 0;
      background: unset;

      div {
        line-height: 20px;
        &.chosenItemTextDropdownInput {
          color: $dark_grey;
          font-weight: 600;
          &.placeholderDropdown {
            color: $grey !important;
          }
        }
        &.personIconDropdownInput {
          margin-top: -2px;
        }
      }

      &:after {
        display: none;
      }

      &:focus {
        box-shadow: unset;
      }
    }
    .dropdown-menu {
      width: 100%;
      background: white;
      box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2);
      border-radius: 5px;
      a {
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
        color: $dark_grey;
        white-space: normal;
        display: flex;
        &:hover {
          background-color: $light_blue;
          font-weight: bold;
          color: white;
        }
        .countItemDropdownInput {
          margin-left: auto;
          color: $dark_grey;
          font-weight: normal;
          font-size: 12px;
          line-height: 22px;
          text-align: center;
          background-color: $light_grey;
          border-radius: 3px;
          height: 22px;
          width: 22px;
        }
      }
    }
  }
}
