@import "../../res/consts/colors.scss";

.pageContainerLoginPage{
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .containerLoginPage{
        display: flex;
        border: 1px solid #EAEEF1;
        box-sizing: border-box;
        //box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
        border-radius: 5px;
        .leftsideContainerLoginPage{
            padding: 80px 50px;
            @media(max-width: 400px){
                padding: 10px;
            }
            .titleLoginPage{
                margin-bottom: 30px;
                .textTitleLoginPage{
                    color:$dark_blue;
                    font-weight: normal;
                    font-size: 24px;
                    line-height: 32px;
                }
            }
            form{
                display: grid;
                
                .actionsLoginPage{
                    display: flex;  
                    z-index: 1;
                    button{
                        background: $blue_gradient !important;
                        font-weight: bold;
                        font-size: 14px;
                        line-height: 19px;
                        color:white;
                        text-align: center;
                        letter-spacing: 0.1px;
                        border:unset;
                        border-radius: 5px;
                        padding: 10px 15px;


                    }
                    .forgotpasswordLoginPage{
                        color: $dark_blue;
                        font-weight: normal;
                        font-size: 16px;
                        line-height: 32px;
                        margin-left: auto;
                        cursor: pointer;
                    }
                }
                .supportMail{
                    margin-top: 20px;
                    display: block;
                    text-decoration: unset;
                }
                .titleForgotPasswordPage{
                    max-width: 300px;
                    color:$black;
                    font-weight: bold;
                    font-size: 22px;
                    line-height: 30px;
                    margin: 20px 0;
                }
                .subTitleForgotPasswordPage{
                    max-width: 300px;
                    color:$dark_grey;
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                    margin-bottom: 20px;
                }
            }
        }
        .oneSimpleInput{
            position: absolute;
            left:0;
            top:0;
            border:unset;
            width:100%;
            height: 100%;
            cursor:default;
            opacity: 0;
        }
        .rightsideContainerLoginPage{
            background-size: cover !important;
            border-radius: 0 5px 5px 0;
            @media(max-width:800px){
                display: none;
            }
        }
    }
}