.basicPageContainer {
  margin-top: 30px;
  height: 0;
  &.container {
    padding: unset;
  }
  /*@media (max-width: 768px) {
    border: unset;
    box-shadow: unset;
  }*/

  //@media (min-width: 992px) {
    max-width: 95% !important;
  //}
}
