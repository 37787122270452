@import "../../../res/consts/colors.scss";

.AnsweredContainerScroolBar {
  padding-right: 22px;
  overflow-y: auto;
  padding:5px 22px 5px 5px;
  margin: -5px 0 0 14px;
  div:nth-child(1) {
    margin-top: 0;
  }
  &::-webkit-scrollbar-thumb {
    background: #6db6fe;
    border-radius: 5px;
  }
  &::-webkit-scrollbar {
    background: #f6f8fc;
  }
  &::-webkit-scrollbar {
    width: 17px;
  }
}
.AnsweredContainer {
  display: flex;
  border-radius: 5px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
  padding: 5px 4px 5px 4px;
  margin-top: 10px;
  min-height: 58px;

  .AnsweredContainerWithoutLeftLine{
    display: flex;
    flex-direction: column;
    width:100%;

    &.AnsweredContainerWithoutLeftLineAcive{
      flex-direction: column;

      .spanDiv{
        width: 100%;
        max-width: unset;
        border: unset;
        .userInfoWrapp{
          grid-gap:unset;
          gap:unset;

          & :first-child{
            flex-grow: 1;
          }
          span{
            margin: 10px 5px 0 0;
          }
        }
      }
    }

    textarea {
      flex-grow: 1;
      width: calc(100% - 5px);
      border: unset;
      background: unset;
      word-wrap: auto;
      word-break: normal;
      resize: none;
      display: block;
      border: 1px solid #6db6fe;
      height: 100%;
      caret-color:#0F56E0;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #1c1c1c;
      &:focus-visible {
        border: unset;
        outline: unset;
      }
      &::-webkit-scrollbar-thumb {
        width: 5px;
        height: 5px;
        background-color: rgb(189, 189, 189);
        border-radius: 2px;
      }
      &::-webkit-scrollbar {
        display: none;
      }
      &::-webkit-scrollbar {
        display: block;
        width: 5px;
        height: 5px;
        background-color: gray;
        margin: 5px 10px 5px 0px;
      }
      &::placeholder {
        color: rgba(234, 238, 241, 1);
      }

      .messageIconWrap {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        gap: 15px;

        img {
          cursor: pointer;
        }
      }
    }
  }

  .userInfoWrapp {
    display: flex;
    gap: 60px;
    .nameLastName {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #babcc9;
    }
  }
  .enptyAnswersWrap {
    color: #babcc9;
  }
  .kritikalPolos {
    border-radius: 1px;
    border: 1px solid;
    margin-right: 5px;
  }
  .spanDiv {
    //max-width: 263px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    /*@media (max-width: 760px) {
      width: 90px;
    }*/
  }
  .answerDateName {
    width: 100%;
    padding: 15px 0 8px;
  }
}
.imageWrapper {
  button {
    border: none;
    background: none;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
  }
}

.blueCircleAnswerd {
  width: 5px;
  height: 5px;
  border-radius: 50px;
  background: #0f56e0;
  position: absolute;
  right: 0;
}

.notAnswer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #babcc9;
  margin-left: 24px;
  margin-right: 20px;
}

.AnsweredQuestionQuestionText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: $black;
  padding: 5px 15px 0 0;
  word-break: break-word;

  span{
    word-break: break-word;
  }
}

.AnsweredQuestionQuestionTextGrey {
  color: $dark_grey;
  padding: 0;
}

.cockpitBlock {
  margin-bottom: 30px;
}

@media only screen and (max-width: 749px) {
  .AnsweredQuestionQuestionText,
  .notAnswer,
  .AnsweredContainer > .AnsweredContainerWithoutLeftLineAcive  > .textAreaWrappAnswer > textarea{
    font-size: 25px;
    line-height: 25px;
  }

  .nameLastName {
    font-size: 19px !important;
  }

  .cockpitUnswerTitileBlockAnaswered .answeredCountWrapp {
    top: -6px;
    padding: 18px;
  }

  .AnsweredQuestionQuestionText.microsoftEdgeBrowser,
  .AnsweredQuestionQuestionText.microsoftEdgeBrowser.AnsweredQuestionQuestionTextGrey,
  .notAnswer.microsoftEdgeBrowser,
  .AnsweredContainer > .AnsweredContainerWithoutLeftLineAcive  > .textAreaWrappAnswer.microsoftEdgeBrowser > textarea{
    font-size: 29px;
  }

  .nameLastName.microsoftEdgeBrowser {
    font-size: 23px !important;
  }
}

@media only screen and (max-width: 601px) {
  .AnsweredQuestionQuestionText,
  .notAnswer,
  .AnsweredContainer > .AnsweredContainerWithoutLeftLineAcive  > .textAreaWrappAnswer > textarea{
    font-size: 27px;
  }

  .nameLastName {
    font-size: 21px !important;
  }
}

@media only screen and (max-width: 501px) {
  .AnsweredQuestionQuestionText,
  .notAnswer,
  .AnsweredContainer > .AnsweredContainerWithoutLeftLineAcive  > .textAreaWrappAnswer > textarea{
    font-size: 29px;
  }

  .nameLastName {
    font-size: 23px !important;
  }
}
