@import "../../res/consts/colors.scss";

.containerOpportDiagrams {
  border: 1px solid #eaeef1;
  box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
  border-radius: 5px;
  @media (max-width: 750px) {
    margin-bottom: 10px;
  }
  .opportunityCarousel {
    width: 100%;
    overflow-x: hidden;
    background: #1e74ec;
    border-radius: 10px;
    padding: 15px 10px;
    min-height: 190px;
    /*@media (max-width: 750px) {
      width: 100%;
      margin-top: 10px;
      height: 170px;
    }*/
  }

  /*media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    .containerTotalDiagram {
      background: #f6f8fc;
      border-radius: 5px;
      flex-direction: column-reverse;
      .rightTitleTotalDiagram {
        text-align: center;
        margin-left: unset;
        margin-bottom: 20px;
        span {
          width: 100%;
        }
      }
    }
  }*/
  .backToOverviewContainer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-right: 20px;
    padding-left: 20px;
    line-height: 20px;
    p{
      margin: 20px 0;
    }
    div{
      font-size: 16px;
      line-height: 19px;
    }

    .refreshButton {
      display: flex;
      align-items: center;
      height: 36px;
      width: 130px;
      padding: 10px 15px;
      background-color: $light_grey;
      cursor: pointer;
      border-radius: 5px;
      border: 1px solid $middle_grey;
      margin-top: 0;
      margin-bottom: 20px;

      span{
        color:$black;
        line-height: 16px;
        font-size: 16px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
  }
  .containerOpportDiagramsContainer {
    display: flex;
    padding: 0px 20px 20px 20px;
    //min-height: 200px;
    /*@media (max-width: 750px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow-x: auto;
      box-sizing: border-box;
    }*/
  }
}
.salesTittle {
  color: #1c1c1c;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin-top: 15px;
}

@media only screen and (max-width: 749px) {
  .containerOpportDiagrams > .backToOverviewContainer > .refreshButton > span {
    font-size: 29px;
  }

  .containerOpportDiagrams > .backToOverviewContainer > .refreshButton {
    width: 180px;
  }
}