@import "../../res/consts/colors.scss";
@import "../../res/consts/global_constants.scss";

header.mainHeader {
  background: $blue_gradient;

  .container {
    font-size: 14px;
    padding: unset;
    max-width:95%;

    @media (max-width: 800px) {
      font-size: 12px;
    }

    @media (max-width: 600px) {
      font-size: 10px;
    }

    .contentContainerMainHeader {
      height: 82px;
      .headerLogoWrapperLeft {
        position:unset;
        max-height: 20px;
      }
      .headerLogo {
        width: 90%;

        @media (max-width: 800px) {
          width: 80%;
        }

        @media (max-width: 600px) {
          width: 70%;
        }
      }

      @media (min-width: 541px) and (max-width: 992px) {
        height: 60px;
        img{
          height: 18px;
        }
      }
      @media (max-width: 600px) {
        height: 35px;
        margin-bottom: -15px;
        img{
          height: 12px;
        }
        .headerUserName{
          font-size: 8px !important;
        }
        .headerLoagout{
          font-size: 8px !important;
        }
      }
      /*.initialsMainHeader {
        @media (max-width: 576px) {
          margin-top: 10px;
        }
      }*/
    }
    .align-items-center {
      position: relative;
    }
    .row {
      /*@media (max-width: 576px) {
        height: auto !important;
        .containerBreadcrumbsMainHeader {
          margin-bottom: 0;
        }
      }*/
      /*@media (max-width: 767px) {
        margin: unset;
        &.withBreadcrumbsMainHeader {
          height: auto !important;
          .containerBreadcrumbsMainHeader {
            margin-left: auto;
            @media (min-width: 577px) {
              margin-bottom: 24px;
            }
          }
        }
      }*/
      .headerInitials {
        color: white;
        background-color: $dark_blue;
        text-align: center;
        width: 29px;
        line-height: 29px;
        border-radius: 100%;
        font-weight: 700;

        @media (max-width: 800px) {
          width: 23px;
          height: 23px;
          line-height: 23px;
          font-size: 11px;
        }

        @media (max-width: 600px) {
          width: 15px;
          height: 15px;
          line-height: 15px;
          font-size: 9px;
        }
      }
      .headerInitialsAvatar {
        color: white;
        background-color: $dark_blue;
        text-align: center;
        width: 29px;
        height: 29px;
        border-radius: 100%;
        font-weight: 700;

        @media (max-width: 800px) {
          width: 23px;
          height: 23px;
        }

        @media (max-width: 600px) {
          width: 15px;
          height: 15px;
        }
      }
      .headerUserName {
        white-space: nowrap;
        color: white;
        font-family: "Open Sans", sans-serif !important;
      }
      .headerLogout {
        color: $light_blue;
        cursor: pointer;
        margin-right: 13px;

        @media (max-width: 400px) {
          margin-right: 0;
        }
      }

      .headerUserName,
      .headerLogout {
        font-size: 14px;

        @media (max-width: 800px) {
          font-size: 12px;
        }

        @media (max-width: 600px) {
          font-size: 10px;
        }
      }
    }
  }
}
