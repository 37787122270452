.cockpitRoot {
  margin-top: 20px;
  border: 1px solid #eaeef1;
  box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
  border-radius: 5px;
}

.cockpitWrapperAnswerdAnanswerd {
  @media (max-width: 749px) {
    overflow-x: scroll;

    &::-webkit-scrollbar-thumb {
      background: #6db6fe;
      border-radius: 5px;
    }

    &::-webkit-scrollbar {
      background: #f6f8fc;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
  }
}

.cockpitHeader {
  display: flex;
  align-items: center;
  padding: 30px 20px 0px 20px;
}

.cockpitTitle {
  margin-top: 11px;
  margin-left: 18px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: #1c1c1c;
}

.cockpitCloseImge {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.cockpitAnswerdAnanswerd {
  display: flex;
  padding: 15px 20px 20px 20px;

   @media (max-width: 749px) {
     width: 200%;
   }

  .cockpitAnswered {
    width: 49%;
    position: relative;
  }
  // @media (max-width: 1200px) {
  //   display: flex;
  //   align-items: center;
  //   flex-direction: column;
  // }
}

.cockpitUnswerTitileBlockAnaswered {
  display: flex;
  padding-left: 26px;
  .answeredCountWrapp {
    width: 31px;
    height: 22px;
    background: #f6f8fc;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    position: relative;
    top: 4px;
  }
}
.cockpitUnswerTitileBlock {
  display: flex;
  .answeredCountWrapp {
    width: 31px;
    height: 22px;
    background: #f6f8fc;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    position: relative;
    top: 4px;
  }
}

.cockpitUnswerTitile {
  font-weight: 700;
  font-size: 18px;
  font-style: normal;
}

.asweredCount {
  margin: 0 auto;
  font-size: 16px;
}

.cockpitAnanswered {
  width: 50%;
  position: relative;
  margin-left: 0;

  @media (max-width: 749px) {
    margin-left: 20px;
  }
}

.meetingCockpitWrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .imgWrapperCockpit {
    width: 360px;
    height: 45px;
  }
  .meetingCockpitSpan {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #babcc9;
    margin-bottom: 35px;
    margin-top: 50px;

    @media only screen and (max-width: 749px) {
      font-size: 25px;

      &.microsoftEdgeBrowser {
        font-size: 29px;
      }
    }

    @media only screen and (max-width: 601px) {
      font-size: 27px;
    }

    @media only screen and (max-width: 501px) {
      font-size: 29px;
    }
  }
}
