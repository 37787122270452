
.containerCardCarousel{
    position: relative;
    height: fit-content;
    width: 70%;
    .arrowsCardCarousel{
        
        .arrowLeftCardCarousel{
            position: absolute;
            top:calc(50% - 6px);
            left: 0;

        }
        .arrowRightCardCarousel{
            position: absolute;
            top:calc(50% - 6px);
            right: 0;
        }
        img{
            cursor: pointer;
            z-index: 9;
        }
    }
    .overflowCardCarousel{
        overflow: hidden;
        margin: 0 auto;
        .carouselCardCarousel{
            display: flex;
            position: relative;
            z-index: 1;
            transition: 1s;
        }
    }
}