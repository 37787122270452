@import "../../../res/consts/colors.scss";


.containerSingleObjectiveDiagram{
    display: flex;
    flex-direction: column;
    align-items: center;
    width:150px !important;
    cursor: pointer;

    .circleWrapSingleObjectiveDiagram {
        //margin: 120px auto;
        width: 120px;
        height: 120px;
        background: unset;
        border-radius: 50%;
        //border: 1px solid #cdcbd0;
        .circleSingleObjectiveDiagram{
            .maskMint,.fillMint {
                width: 120px;
                height: 120px;
                position: absolute;
                border-radius: 50%;
            }
            .maskMint {
                clip: rect(0px, 120px, 120px, 59px);
                z-index: 2;
                &.fullMint{
                    animation: fill ease-in-out 3s;
                    //transform: rotate(90deg);
                    .circle{
                        width:100%;
                        height: 100%;
                        border-radius: 100%;
                        &.AnswerColor0{
                            background-color:$green;
                        }
                        &.AnswerColor2{
                            background-color:$diagram_red;
                        }
                        &.AnswerColor1{
                            background-color:$pink;
                        }
                    }
                }
                .fillMint{
                    animation: fill ease-in-out 3s;
                    //transform: rotate(90deg);
                    clip: rect(0px, 60px, 120px, 0px);
                    &.AnswerColor0{
                        background-color:$green;
                    }
                    &.AnswerColor2{
                        background-color:$diagram_red;
                    }
                    &.AnswerColor1{
                        background-color:$pink;
                    }
                }
            }

            //grey circle

            .maskGrey,.fillGrey {
                width: 110px;
                height: 110px;
                position: absolute;
                border-radius: 50%;
            }
            .maskGrey {
                //clip: rect(0px, 110px, 110px, 55px);
                z-index: 1;
                margin-top: 5px;
                margin-left: 5px;
                opacity: 0.5;
                &.fullGrey{
                    //transform: rotate(180deg);
                }
                .fillGrey{
                    transform: rotate(180deg);
                    //clip: rect(0px, 55px, 110px, 0px);
                    background-color: $light_blue;
                    opacity: 0.5;
                }
            }

            .insideCircle {
                width: 72px;
                height: 72px;
                border-radius: 50%;
                background: $diagram_blue;
                line-height: 70px;
                text-align: center;
                margin-top: 24px;
                margin-left: 24px;
                color: white;
                position: absolute;
                z-index: 100;
                font-weight: 400;
                font-size: 16px;
            }
            .circleDevider{
                height: 60px;
                border:2px solid $diagram_blue;
                background-color: $diagram_blue;
                width: 0;
                margin-left: 58px;
                z-index: 5;
                position: absolute;
                transform-origin: bottom;
            }
            
        }
    }

    .titleSingleObjectiveDiagram{
        font-size: 14px;
        line-height: 19px;
        font-weight: 600;
        max-width: 115px;
        color: white;
        display: flex;
        text-align: center;
        margin-top: 10px;
    }
}
  
  
  


