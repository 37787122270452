@import "../../res/consts/colors.scss";

.containerUserInfoPage{
    //position: relative;
    .hint {
        margin-left: 5px;
        color: #616161;
    }
    .topFilter{
        background-color: $light_grey;
        border-radius: 5px;
        display: flex;
        grid-gap:20px;
        .containerDropdownInput{
            flex-grow: 1;
        }
        .refreshButton{
            display: flex;
            align-items: center;
            height: 36px;
            padding: 10px 15px;
            background-color: white;
            cursor:pointer;
            border-radius: 5px;
            border: 1px solid #EAEEF1;
            span{
                color:$black;
                line-height: 16px;
                font-size: 12px;
                font-weight: 600;
                margin-left: 10px;
            }
        }
}
}
