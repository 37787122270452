//blue
$blue_gradient: linear-gradient(
  316.17deg,
  #2f9bfb -12.7%,
  #1562e5 55.13%,
  #0d52de 98.99%
);
$dark_blue: #0f56e0;
$light_blue: #6db6fe;
$diagram_blue: #1e74ec;

//grey
$grey: #babcc9;
$gray: #919dc2;
$light_grey: #f6f8fc;
$dark_grey: #616161;
$middle_grey: #eaeef1;
$chat_grey: #8e8e93;
$chat_grey_background: rgba(118, 118, 128, 0.12);

//green
$mint: #32ddbf;
$green: #75da92;

$light_green: #e1fdf8;

//yellow
$light_yellow: #fbf3d7;
$yellow: #ffe071;

//red
$light_red: #fdc6d6;
$red: #ff0650;
$pink: #f7949e;
$light_pink: #f8e0e2;
$diagram_red: #e93355;

//black
$black: #1c1c1c;

//border
$border_white: #f6f8fc;
