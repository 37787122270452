@import "../../../res/consts/colors.scss";

.containerTotalDiagram {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .circleWrapTotalDiagram {
    width: 140px;
    height: 140px;
    background: unset;
    border-radius: 50%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    .circleTotalDiagram {
      margin-left: -140px;
      .maskMint,
      .fillMint {
        position: absolute;
        border-radius: 50%;
      }
      .maskMint {
        z-index: 2;
        &.fullMint {
          animation: fill ease-in-out 3s;
          .circle {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            border: 2px solid $diagram_blue;
            &.default {
              background-color: $green;
            }
            &.low {
              background-color: $pink;
            }
            &.hight {
              background-color: $diagram_red;
            }
          }
        }
        .fillMint {
          animation: fill ease-in-out 3s;
          background-color: $green;
          //border: 2px solid $diagram_blue;
          &.default {
            background-color: $green;
          }
          &.low {
            background-color: $pink;
          }
          &.hight {
            background-color: $diagram_red;
          }
        }
      }

      //grey circle

      .maskGrey,
      .fillGrey {
        width: 140px;
        height: 140px;
        position: absolute;
        border-radius: 50%;
      }
      .maskGrey {
        clip: rect(0px, 140px, 140px, 69px);
        z-index: 1;

        &.fullGrey {
          transform: rotate(180deg);
        }
        .fillGrey {
          transform: rotate(180deg);
          clip: rect(0px, 70px, 140px, 0px);
          background-color: $light_blue;
          opacity: 0.5;
        }
      }

      .insideCircle {
        width: 62px;
        height: 62px;
        border-radius: 50%;
        background: $diagram_blue;
        line-height: 60px;
        text-align: center;
        margin-top: 39px;
        margin-left: 39px;
        color: white;
        position: absolute;
        z-index: 500;
        font-weight: 700;
        font-size: 20px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        span {
          font-weight: bold;
          font-size: 10px;
          line-height: 14px;
          margin-bottom: auto;
          line-height: 57px;
        }
      }
      .circleDevider {
        height: 70px;
        border: 2px solid $diagram_blue;
        background-color: $diagram_blue;
        width: 0;
        margin-left: 68px;
        z-index: 499;
        position: absolute;
        transform-origin: bottom;
      }
    }
    .titleTotalDiagram {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
      max-width: 115px;
      color: white;
      text-align: center;
      position: absolute;
      bottom: -30px;
      z-index: 7;
      text-transform: uppercase;
    }
  }
  .rightTitleTotalDiagram {
    max-width: 152px;
    font-size: 20px;
    font-weight: 700;
    line-height: 22px;
    color: white;
    margin-left: 18px;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    .companyNameTotalDiagram {
      color: $light_blue;
      font-size: 12px;
      font-weight: 600;
      line-height: 16px;
      margin-bottom: 10px;
      align-items: flex-start !important;
      span {
        max-width: 152px !important;
        white-space: normal !important;
      }

      .slash {
        font-size: 14px;
        position: relative;
        bottom: -2px;
      }
    }
  }
}

.refreshButton {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 10px 15px;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #6db6fe;
  width: 112px;
  margin-top: 20px;
  margin-bottom: -30px;
  z-index: 10;
  /*@media (max-width: 768px) {
    margin-bottom: 5px;
    margin-left: 20px;
  }*/
  span {
    color: #6db6fe;
    line-height: 16px;
    font-size: 12px;
    font-weight: 600;
    margin-left: 10px;
  }
}
