@import "../../../res/consts/colors.scss";

.containerSingleOpportDiagramOpp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px !important;
  height: 100%;
  padding-top: 10px;
  cursor: pointer;
  .clousAnswerPanel {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: end;
    .closeImgeContainer{
      background-color: #202763;
      border-radius: 100%;
      opacity: 0.5;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 5px;
      .closeImge {
        width: 18px;
        height: 18px;
        position: absolute;
        
      }
    }
  }
  .closeedCaruse {
    display: none;
  }

  .circleWrapSingleOpportDiagramOpp {
    width: 120px;
    height: 120px;
    background: unset;
    border-radius: 50%;
    .circleSingleOpportDiagramOpp {
      .maskMintOpport,
      .fillMintOpport {
        width: 120px;
        height: 120px;
        position: absolute;
        border-radius: 50%;
      }
      .maskMintOpport {
        clip: rect(0px, 120px, 120px, 59px);
        z-index: 2;
        &.fullMintOpport {
          animation: fill ease-in-out 3s;
          //transform: rotate(90deg);
          .circle {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            &.AnswerColor0 {
              background-color: $green;
            }
            &.AnswerColor2 {
              background-color: $diagram_red;
            }
            &.AnswerColor1 {
              background-color: $pink;
            }
          }
        }
        .fillMintOpport {
          animation: fill ease-in-out 3s;
          //transform: rotate(90deg);
         clip: rect(0px, 60px, 120px, 0px);
          &.AnswerColor0 {
            background-color: $green;
          }
          &.AnswerColor2 {
            background-color: $diagram_red;
          }
          &.AnswerColor1{
            background-color: $pink;
          }
        }
      }

      .maskGreyOpport,
      .fillGreyOpport {
        width: 120px;
        height: 120px;
        position: absolute;
        border-radius: 50%;
      }
      .fillGreyOpport {
        &.smallСircle {
          width: 110px;
          height: 110px;
          border: 12px solid $light_blue;
          background-color: transparent !important;
        }
      }
      .maskGreyOpport {
        //clip: rect(0px, 110px, 110px, 55px);
        z-index: 1;
        display: flex;
        justify-content: center;
        &.fullGreyOpport {
          //transform: rotate(180deg);
        }
        .fillGreyOpport {
          transform: rotate(180deg);
          //clip: rect(0px, 55px, 110px, 0px);
          background-color: $light_blue;
        }
        &.smallСircle {
          margin-top: 6px;
        }
      }

      .insideCircleOpport {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 85px;
        height: 85px;
        border-radius: 50%;
        background: #1e74ec;
        text-align: center;
        margin-top: 18px;
        margin-left: 18px;
        position: absolute;
        z-index: 100;
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #ffffff;
      }
      .circleDeviderOpport {
        height: 60px;
        border: 2px solid $diagram_blue;
        background-color: $diagram_blue;
        width: 0;
        margin-left: 58px;
        z-index: 5;
        position: absolute;
        transform-origin: bottom;
      }
    }
  }

  .titleSingleObjectiveDiagram {
    font-size: 16px;
    line-height: 19px;
    font-weight: 400;
    color: white;
    display: flex;
    text-align: center;
    margin: 15px 5px 10px;
  }
}

.activColor {
  border-radius: 5px;
  border: 2px solid rgba(109, 182, 254, 0.5);
  height: auto;
  margin-bottom: 1px;
}

.activColorDiagramInsaide {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85px;
  height: 85px;
  border-radius: 50%;
  background: #1e74ec;
  text-align: center;
  margin-top: 18px;
  margin-left: 18px;
  position: absolute;
  z-index: 100;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}

@media only screen and (max-width: 749px) {
  .containerSingleOpportDiagramOpp > .titleSingleObjectiveDiagram,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .insideCircleOpport,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .activColorDiagramInsaide,
  .containerOpportDiagrams > .backToOverviewContainer > div > .goBackObjectiveDetailsPage{
    font-size: 25px;
    line-height: 25px;
  }

  .containerSingleOpportDiagramOpp {
    width: 200px !important;
  }

  .containerSingleOpportDiagramOpp > .titleSingleObjectiveDiagram.microsoftEdgeBrowser,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .insideCircleOpport.microsoftEdgeBrowser,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .activColorDiagramInsaide.microsoftEdgeBrowser,
  .containerOpportDiagrams > .backToOverviewContainer > div > .goBackObjectiveDetailsPage.microsoftEdgeBrowser{
    font-size: 29px;
  }
}

@media only screen and (max-width: 601px) {
  .containerSingleOpportDiagramOpp > .titleSingleObjectiveDiagram,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .insideCircleOpport,
  .containerSingleOpportDiagramOpp > .circleWrapSingleOpportDiagramOpp > .circleSingleOpportDiagramOpp > .activColorDiagramInsaide,
  .containerOpportDiagrams > .backToOverviewContainer > div > .goBackObjectiveDetailsPage{
    font-size: 27px;
  }
}

@media only screen and (max-width: 501px) {
  .containerOpportDiagrams > .backToOverviewContainer > div > .goBackObjectiveDetailsPage{
    font-size: 29px;
  }
}
