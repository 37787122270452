.buttonsPanel {
  padding: 15px 0 15px 0;
  background: #f6f8fc;
  border: 1px solid #eaeef1;
  display: flex;
  justify-content: center;
  .buttonsPanelWrapp {
    display: flex;
    justify-content: center;
    gap: 20px;
    .answerPriorityButton{
      display: flex;
      align-items: center;
      padding: 10px;
      border-radius: 20px;
      border:1px solid;
      gap:10px;
      min-width:101px;
      cursor:pointer;
      .answerCircle{
        width: 16px;
        height: 16px;
        background-color: white;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border:1px solid;
        img{

        }
      }
      span{
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
      }
    }
  }
}

@media only screen and (max-width: 749px) {
  .buttonsPanel > .buttonsPanelWrapp > .answerPriorityButton > span {
    font-size: 22px;
  }

  .buttonsPanel .buttonsPanelWrapp .answerPriorityButton {
    min-width: 145px;
  }

  .buttonsPanel .buttonsPanelWrapp .answerPriorityButton.microsoftEdgeBrowser {
    min-width: 155px;
  }

  .buttonsPanel > .buttonsPanelWrapp > .answerPriorityButton.microsoftEdgeBrowser > span {
    font-size: 27px;
  }
}

@media only screen and (max-width: 601px) {
  .buttonsPanel > .buttonsPanelWrapp > .answerPriorityButton > span {
    font-size: 25px;
  }

  .buttonsPanel .buttonsPanelWrapp .answerPriorityButton {
    min-width: 150px;
  }
}

@media only screen and (max-width: 501px) {
  .buttonsPanel > .buttonsPanelWrapp > .answerPriorityButton > span {
    font-size: 27px;
  }
  .buttonsPanel .buttonsPanelWrapp .answerPriorityButton {
    min-width: 155px;
  }

}
