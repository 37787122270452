@import "../../res/consts/colors.scss";

.pageContainerResetPasswordPage{
    width:100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-repeat: no-repeat !important;
    background-position-x: center !important;
    background-position-y: 23% !important;
    /*@media(max-width: 767px){
        background-size: contain !important;
        background-position-y: 11% !important;
    }*/
    .containerResetPasswordPage{
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #EAEEF1;
        box-sizing: border-box;
        box-shadow: 0px 2px 10px rgba(186, 188, 201, 0.5);
        border-radius: 5px;
        width:100%;
        max-width: 800px;
        padding: 80px 0;
        background-color: white;
        button{
            background: $blue_gradient !important;
            font-weight: bold;
            font-size: 14px;
            line-height: 19px;
            color:white;
            text-align: center;
            letter-spacing: 0.1px;
            border:unset;
            border-radius: 5px;
            padding: 10px 15px;
        }
        .titleResetPasswordPage{
            color:$black;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            margin-bottom: 30px;
        }
        .mismatchErrorResetPasswordPage{
            color:$red;
            font-weight: normal;
            font-size: 12px;
            line-height: 16px;
        }
    }
}