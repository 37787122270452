@import "../../res/consts/colors.scss";

.containerEmptyPage{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    img{
        margin-left: -50px;
        /*@media(max-width:768px){
            max-width:320px;
            margin-left: -30px;
        }*/
    }
    .titleEmptyPage{
        color:$black;
        font-weight: bold;
        font-size: 32px;
        line-height: 44px;
        margin-top: 30px;
        text-align: center;
    }
    .subtitleEmptyPage{
        font-weight: normal;
        font-size: 16px;
        line-height: 22px;
        color:$dark_grey;
        margin-top: 10px;
        text-align: center;
    }
}