@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
*{
    font-family: 'Open Sans', sans-serif;
}
.mainContainer{
    width:1200px;
    margin-left: auto;
    margin-right: auto;
    div{
        &::-webkit-scrollbar-thumb {
            height: 5px;
            width:5px;
            background: rgb(189, 189, 189);
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            display: none;
        }
        &::-webkit-scrollbar {
            display: block;
            height: 5px;
            width:5px;
            margin: 5px 10px 5px 0px;
        }
    }
}

body{
    overflow-x: hidden;
}